import React from "react";
import html2canvas from "html2canvas";
import styles from "./capture_button.module.css"; // 스타일을 위한 CSS 모듈

const CaptureButton = ({ reportCardRef }) => {
  const captureAndCopyToClipboard = async () => {
    if (!reportCardRef.current) return;
    try {
      const canvas = await html2canvas(reportCardRef.current);
      canvas.toBlob(async (blob) => {
        if (!blob) return;

        if (!navigator.clipboard) {
          alert("클립보드 API가 지원되지 않는 브라우저입니다.");
          return;
        }

        try {
          const item = new ClipboardItem({ "image/png": blob });
          await navigator.clipboard.write([item]);
          alert("ReportCard가 캡쳐되어 클립보드에 저장되었습니다.");
        } catch (error) {
          console.error(
            "클립보드에 이미지를 저장하는 중 오류가 발생했습니다.",
            error
          );
        }
      });
    } catch (error) {
      console.error("ReportCard를 캡쳐하는 중 오류가 발생했습니다.", error);
    }
  };

  return (
    <img
      className={styles.captureButton}
      src={`icon_capture.png`}
      onClick={captureAndCopyToClipboard}
      alt={"capture"}
    />
  );
};

export default CaptureButton;
