import React from "react";
import styles from "./quiz_content_4.module.css";

const QuizContent4 = ({
  question,
  currentQuestionIndex,
  onAnswerSelected,
  selectedAnswer,
  isCorrect,
}) => {
  const renderExampleWithLineBreaks = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  return (
    <div className={styles.Conetent}>
      <div className={styles.questionContainer}>
        {isCorrect !== undefined && (
          <img
            className={styles.redPen}
            src={isCorrect ? "./correct.png" : "./wrong.png"}
            alt={isCorrect ? "Correct" : "Wrong"}
          />
        )}
        <p>{`${currentQuestionIndex + 1}. ${question.question} [${
          question.score
        }점]`}</p>
      </div>

      <section className={styles.imageSection}>
        <img className={styles.image} src={question.image} alt={`question`} />
      </section>
      {question.example && (
        <section className={styles.example}>
          <p>{renderExampleWithLineBreaks(question.example)}</p>
        </section>
      )}
      {question.options ? (
        <section className={styles.optionSection}>
          <ul>
            {question.options.map((option, index) => (
              <div
                key={index}
                className={
                  onAnswerSelected
                    ? `${styles.option} ${
                        selectedAnswer === index ? styles.selected : ""
                      }`
                    : `${styles.nohover}`
                }
                onClick={
                  onAnswerSelected ? () => onAnswerSelected(index) : null
                }
              >
                <div
                  className={`${styles.optionNumber} ${
                    selectedAnswer === index ? styles.selected : ""
                  }`}
                >
                  {isCorrect !== undefined && index === question.answer - 1 && (
                    <img
                      className={styles.check}
                      src={"./check.png"}
                      alt={`check-${index}`}
                    />
                  )}
                  {selectedAnswer === index ? "" : index + 1}
                </div>
                <div className={styles.optionText}>{option}</div>
              </div>
            ))}
          </ul>
        </section>
      ) : (
        <section className={styles.optionSection}>
          <div style={{ display: "flex", width: "50%", paddingTop: "20px" }}>
            <div style={{ width: "50%", textAlign: "center" }}>(ㄱ)</div>
            <div style={{ width: "50%", textAlign: "center" }}>(ㄴ)</div>
          </div>
          <ul className={styles.options}>
            {question.options1.map((option, index) => (
              <div
                key={index}
                className={
                  onAnswerSelected
                    ? `${styles.option} ${
                        selectedAnswer === index ? styles.selected : ""
                      }`
                    : `${styles.nohover}`
                }
                onClick={
                  onAnswerSelected ? () => onAnswerSelected(index) : null
                }
              >
                <div
                  className={`${styles.optionNumber} ${
                    selectedAnswer === index ? styles.selected : ""
                  }`}
                >
                  {isCorrect !== undefined && index === question.answer - 1 && (
                    <img
                      className={styles.check}
                      src={"./check.png"}
                      alt={`check-${index}`}
                    />
                  )}
                  {selectedAnswer === index ? "" : index + 1}
                </div>
                <div className={styles.optionText}>
                  {option}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {question.options2[index]}
                </div>
              </div>
            ))}
          </ul>
        </section>
      )}
    </div>
  );
};

export default QuizContent4;
