import React, { forwardRef } from "react";
import styles from "./quiz_content.module.css";
import QuizContent1 from "../quiz_content_1/quiz_content_1";
import QuizContent2 from "../quiz_content_2/quiz_content_2";
import QuizContent3 from "../quiz_content_3/quiz_content_3";
import QuizContent4 from "../quiz_content_4/quiz_content_4";
import QuizContent5 from "../quiz_content_5/quiz_content_5";
import QuizContent6 from "../quiz_content_6/quiz_content_6";
import QuizContent7 from "../quiz_content_7/quiz_content_7";
import QuizContent8 from "../quiz_content_8/quiz_content_8";

const QuizContent = forwardRef((props, ref) => {
  const {
    question,
    currentQuestionIndex,
    onAnswerSelected,
    selectedAnswer,
    contentRef,
  } = props;
  return (
    <div className={styles.quizContent} ref={contentRef}>
      {question.type === 1 && (
        <QuizContent1
          question={question}
          currentQuestionIndex={currentQuestionIndex}
          onAnswerSelected={onAnswerSelected}
          selectedAnswer={selectedAnswer}
        />
      )}
      {question.type === 2 && (
        <QuizContent2
          question={question}
          currentQuestionIndex={currentQuestionIndex}
          onAnswerSelected={onAnswerSelected}
          selectedAnswer={selectedAnswer}
        />
      )}
      {question.type === 3 && (
        <QuizContent3
          question={question}
          currentQuestionIndex={currentQuestionIndex}
          onAnswerSelected={onAnswerSelected}
          selectedAnswer={selectedAnswer}
        />
      )}
      {question.type === 4 && (
        <QuizContent4
          question={question}
          currentQuestionIndex={currentQuestionIndex}
          onAnswerSelected={onAnswerSelected}
          selectedAnswer={selectedAnswer}
        />
      )}
      {question.type === 5 && (
        <QuizContent5
          question={question}
          currentQuestionIndex={currentQuestionIndex}
          onAnswerSelected={onAnswerSelected}
          selectedAnswer={selectedAnswer}
        />
      )}
      {question.type === 6 && (
        <QuizContent6
          question={question}
          currentQuestionIndex={currentQuestionIndex}
          onAnswerSelected={onAnswerSelected}
          selectedAnswer={selectedAnswer}
        />
      )}
      {question.type === 7 && (
        <QuizContent7
          question={question}
          currentQuestionIndex={currentQuestionIndex}
          onAnswerSelected={onAnswerSelected}
          selectedAnswer={selectedAnswer}
        />
      )}
      {question.type === 8 && (
        <QuizContent8
          question={question}
          currentQuestionIndex={currentQuestionIndex}
          onAnswerSelected={onAnswerSelected}
          selectedAnswer={selectedAnswer}
        />
      )}
    </div>
  );
});

export default QuizContent;
