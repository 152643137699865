import { Route, Routes } from "react-router-dom";
import MainPage from "./pages/main/main";
import NotFoundPage from "./pages/notfound/notfound";
import CoverPage from "./pages/cover/cover";
import QuizPage from "./pages/quiz/quiz";
import ResultPage from "./pages/result/result";
import AdvertisementPage from "./pages/advertisement/advertisement";
import OtherQuizPage from "./pages/other_quiz/other_quiz";
import CheckResultPage from "./pages/check_result/check_result";
import { useEffect } from "react";

function App({ scoreService, shareService }) {
  const setScreenSize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  useEffect(() => {
    setScreenSize();
  });

  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/quiz/:topic" element={<QuizPage />} />
      <Route path="/cover/:topic" element={<CoverPage />} />
      <Route
        path="/result"
        element={<ResultPage shareService={shareService} />}
      />
      <Route path="/other" element={<OtherQuizPage />} />
      <Route path="/check" element={<CheckResultPage />} />
      <Route
        path="/ad"
        element={<AdvertisementPage scoreService={scoreService} />}
      />
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
