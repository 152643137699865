import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./advertisement.module.css";
import KakaoAdFit from "../../components/kakao_adfit/kakao_adfit";
import CoupangAd from "../../components/coupang_ad/coupang_ad";

const AdvertisementPage = ({ scoreService }) => {
  const navigate = useNavigate();
  const [barWidth, setBarWidth] = useState(0); // 초기 바의 너비를 100으로 설정

  const localData = localStorage.getItem("info");
  const encodeData = new TextEncoder().encode(localData);
  const urlData = btoa(String.fromCharCode(...encodeData));
  useEffect(() => {
    const interval = setInterval(() => {
      setBarWidth((prevWidth) => prevWidth + 1); // 매 초마다 바의 너비를 1씩 줄임
    }, 50); // 50밀리초마다 바의 너비를 감소시켜서 5초 동안 감소

    setTimeout(() => {
      clearInterval(interval); // 5초가 지나면 interval을 정지하여 바의 너비를 더 이상 감소하지 않도록 함
      navigate(`/result#${urlData}`);
    }, 5000);

    return () => clearInterval(interval); // 컴포넌트가 언마운트되면 interval을 정지하여 메모리 누수를 방지
  }, [navigate, urlData]);

  useEffect(() => {
    const sendInfoData = () => {
      const infoData = JSON.parse(localStorage.getItem("info"));
      const filteredData = {
        name: infoData.name,
        studentCode: infoData.studentCode,
        score: Object.keys(infoData.score).reduce((result, key) => {
          if (infoData.wait.includes(key)) {
            result[key] = infoData.score[key];
          }
          return result;
        }, {}),
        answers: Object.keys(infoData.answers).reduce((result, key) => {
          if (infoData.wait.includes(key)) {
            result[key] = infoData.answers[key];
          }
          return result;
        }, {}),
      };
      scoreService
        .SaveScoreData(filteredData)
        .then((res) => {
          const storedData = localStorage.getItem("info");
          if (storedData) {
            const infoData = JSON.parse(storedData);
            infoData.wait = [];
            localStorage.setItem("info", JSON.stringify(infoData));

            // console.log("wait 키가 초기화되었습니다.");
          } else {
            // console.log("info 키의 데이터가 존재하지 않습니다.");
          }

          // console.log(res.message);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    sendInfoData();
  }, []);

  useEffect(() => {
    // IntersectionObserver를 사용하여 광고가 보이는지 확인
    const adObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            navigate(`/result#${urlData}`);
          }, 5000); // 광고를 본 후 5초 후에 결과 페이지로 이동
        }
      });
    });

    const adElement = document.getElementById("ad");
    if (adElement) {
      adObserver.observe(adElement);
    }

    // Clean up observer on component unmount
    return () => {
      if (adElement) {
        adObserver.unobserve(adElement);
      }
    };
  }, [navigate, urlData]);

  // useEffect(() => {
  //   const loadAdSense = () => {
  //     const adScript = document.createElement("script");
  //     adScript.src =
  //       "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
  //     adScript.async = true;
  //     adScript.crossOrigin = "anonymous";
  //     adScript.onload = () => {
  //       // console.log("AdSense script loaded");
  //       if (window.adsbygoogle) {
  //         (window.adsbygoogle = window.adsbygoogle || []).push({});
  //       }
  //     };
  //     adScript.onerror = (e) => {
  //       console.error("AdSense script failed to load", e);
  //     };
  //     document.body.appendChild(adScript);

  //     // Clean up script on component unmount
  //     return () => {
  //       document.body.removeChild(adScript);
  //     };
  //   };

  //   if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
  //     loadAdSense();
  //   }
  // }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.infoMessage}>잠시후 결과 페이지로 이동합니다.</h2>
      <div style={{ width: "100%", height: "2em", backgroundColor: "black" }}>
        <div
          style={{
            width: `${barWidth}%`,
            height: "2em",
            backgroundColor: "white",
          }}
        ></div>
      </div>
      <div className={styles.adSection}>
        <CoupangAd />
        {/* <KakaoAdFit unit="DAN-UpQ4r8htxIaQC97W" /> */}
        {/* <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-9204961569189000" // 실제 AdSense ID로 대체
          data-ad-slot="8508921386" // 실제 광고 슬롯 ID로 대체
          data-ad-format="autorelaxed"
          data-full-width-responsive="true"
        ></ins> */}
      </div>
    </div>
  );
};

export default AdvertisementPage;
