import styles from "./clock.module.css";

const Clock = ({ leftTime }) => {
  return (
    <section className={styles.clockSection}>
      <p className={styles.clock}>{`${Math.floor(leftTime / 60)}:${
        leftTime % 60 < 10 ? `0${leftTime % 60}` : leftTime % 60
      }`}</p>
    </section>
  );
};

export default Clock;
