import styles from "./main.module.css";
import QuizList from "../../components/quiz_list/quiz_list";

const MainPage = () => {
  return (
    <>
      <div className={styles.container}>
        <section className={styles.top}>
          <img className={styles.logo} src={"./logo3.png"} alt={"logo3"} />
        </section>
        <QuizList />
      </div>
    </>
  );
};

export default MainPage;
