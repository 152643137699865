import { useEffect, useState } from "react";
import styles from "./cover.module.css";
import { useNavigate, useParams } from "react-router-dom";
import fetchInfo from "../../modules/fetch_info";

const CoverPage = () => {
  const { topic } = useParams();
  const [name, setName] = useState("");
  const [studentCode, setStudentCode] = useState("");

  const localInfo = JSON.parse(localStorage.getItem("info"));
  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);

  let lastName = [
    "튼튼한",
    "똑똑한",
    "친절한",
    "장난스런",
    "행복한",
    "사랑스런",
    "긍정적인",
  ];
  let firstName = [
    "초롱이",
    "밍키",
    "쿠키",
    "뚝딱이",
    "함박이",
    "해롱이",
    "뚱이",
  ];

  const getRandomElement = (list) => {
    const randomIndex = Math.floor(Math.random() * list.length);
    return list[randomIndex];
  };

  const makeRandomName = () => {
    const element1 = getRandomElement(lastName);
    const element2 = getRandomElement(firstName);
    const newCombinedString = element1 + element2;
    return newCombinedString;
  };

  const generateStudentCode = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    const formattedDate = year + month + day;
    const time = today.getTime().toString();
    const random = Math.floor(Math.random() * 99)
      .toString()
      .padStart(2, "0");
    const randit = time + random;
    const studentCode = formattedDate + "-" + randit;

    return studentCode;
  };

  const navigate = useNavigate();

  const onStart = () => {
    // console.log(name, formattedDate, randit);
    sessionStorage.setItem("name", JSON.stringify(name));
    sessionStorage.setItem("code", JSON.stringify(studentCode));
    sessionStorage.setItem("info", JSON.stringify(info));
    navigate(`/quiz/${topic}`);
  };

  const getInfo = async () => {
    try {
      const _info = await fetchInfo();
      setInfo(_info[topic]);
    } catch (error) {
      console.error("Failed to fetch information:", error);
    } finally {
      setLoading(false);
    }
  };

  //로컬스토리지에 값이 있으면 정보를 가져와서 사용하고,
  //없으면 랜덤한값 생성
  useEffect(() => {
    sessionStorage.clear();
    getInfo();
    if (localInfo?.name) {
      setName(localInfo.name);
      setStudentCode(localInfo.studentCode);
      sessionStorage.setItem("name", JSON.stringify(localInfo.name));
      sessionStorage.setItem("code", JSON.stringify(localInfo.studentCode));
    } else {
      const randomName = makeRandomName();
      const newStudentCode = generateStudentCode();
      setName(randomName);
      setStudentCode(newStudentCode);
      sessionStorage.setItem("name", JSON.stringify(randomName));
      sessionStorage.setItem("code", JSON.stringify(newStudentCode));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("name", JSON.stringify(name));
  }, [name]);

  if (loading) {
    <div>Loading...</div>;
  }

  if (!info) {
    return <div>Information not available for the selected topic.</div>;
  }

  return (
    <>
      <div className={styles.container}>
        <section className={styles.title}>
          <h2>{info.title}</h2>
          <h1>{info.title_sub}</h1>
        </section>
        <section className={styles.inputSection}>
          <div className={styles.nameSection}>
            <span className={styles.nameTitle}>성명</span>

            <input
              className={styles.nameInput}
              onChange={onNameChange}
              value={name}
            />
          </div>
          <div className={styles.numSection}>
            <span className={styles.numTitle}>수험번호</span>
            <div className={styles.numRandom}>
              <span>{studentCode}</span>
            </div>
          </div>
        </section>
        <section className={styles.contentSection}>
          <span>
            - 문제지에 성명 및 수험번호를 기재해주십시오.(성명은 변경
            가능합니다.)
          </span>
          <span>
            - 문항에 따라 배점이 다릅니다. 문항마다 기재되어있는 배점을 참고해
            주십시오.
          </span>
          <span>- 총 20문제로 3분간 진행됩니다.</span>
          <span>- 문제를 읽고 정답을 표시해주십시오.</span>
        </section>
        <section className={styles.buttonSection}>
          <button className={styles.buttonStart} onClick={onStart}>
            시험 시작하기
          </button>
        </section>
      </div>
    </>
  );
};

export default CoverPage;
