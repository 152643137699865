import styles from "./quiz_content_3.module.css";

const QuizContent3 = ({
  question,
  currentQuestionIndex,
  onAnswerSelected,
  selectedAnswer,
  isCorrect,
}) => {
  return (
    <div className={styles.Conetent}>
      <div className={styles.questionContainer}>
        {isCorrect !== undefined && (
          <img
            className={styles.redPen}
            src={isCorrect ? "./correct.png" : "./wrong.png"}
            alt={isCorrect ? "Correct" : "Wrong"}
          />
        )}
        <p>{`${currentQuestionIndex + 1}. ${question.question} [${
          question.score
        }점]`}</p>
      </div>

      <section className={styles.imageSection}>
        <img className={styles.image} src={question.image} alt={`question`} />
      </section>

      <ul>
        {question.options.map((option, index) => (
          <div
            key={index}
            className={
              onAnswerSelected
                ? `${styles.option} ${
                    selectedAnswer === index ? styles.selected : ""
                  }`
                : `${styles.nohover}`
            }
            onClick={onAnswerSelected ? () => onAnswerSelected(index) : null}
          >
            <div
              className={`${styles.optionNumber} ${
                selectedAnswer === index ? styles.selected : ""
              }`}
            >
              {isCorrect !== undefined && index === question.answer - 1 && (
                <img
                  className={styles.check}
                  src={"./check.png"}
                  alt={`check-${index}`}
                />
              )}
              {selectedAnswer === index ? "" : index + 1}
            </div>
            <div className={styles.optionText}>{option}</div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default QuizContent3;
