import styles from "./quiz_bottom.module.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const QuizBottom = ({
  currentQuestionIndex,
  totalQuestions,
  onPreviousQuestion,
  onNextQuestion,
  onFinish,
}) => {
  const handleButtonClick = () => {
    if (currentQuestionIndex === totalQuestions - 1) {
      onFinish();
    } else {
      onNextQuestion();
    }
  };
  return (
    <div className={styles.container}>
      <section className={styles.previousSection}>
        {currentQuestionIndex > 0 ? (
          <button
            className={styles.previousButton}
            onClick={() => {
              onPreviousQuestion();
            }}
          >
            <FaArrowLeft style={{}} size={40} />
          </button>
        ) : (
          <div></div>
        )}
      </section>
      <section className={styles.remainSection}>
        <h2>{`${currentQuestionIndex + 1}/${totalQuestions}`}</h2>
      </section>
      <section className={styles.nextSection}>
        <button className={styles.nextButton} onClick={handleButtonClick}>
          {currentQuestionIndex === totalQuestions - 1 ? (
            <div className={styles.finishText}>{"결과제출"}</div>
          ) : (
            <FaArrowRight size={40} />
          )}
        </button>
      </section>
    </div>
  );
};

export default QuizBottom;
