import styles from "./other_quiz.module.css";
import QuizList from "../../components/quiz_list/quiz_list";
import KakaoAdFit from "../../components/kakao_adfit/kakao_adfit";

const OtherQuizPage = () => {
  return (
    <>
      <div className={styles.container}>
        <section className={styles.adBox}>
          <KakaoAdFit unit="DAN-UpQ4r8htxIaQC97W" />
        </section>
        <QuizList />
      </div>
    </>
  );
};

export default OtherQuizPage;
