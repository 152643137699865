import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./check_result.module.css";
import decodeURLData from "../../modules/decode_url_data";
import fetchQuestions from "../../modules/fetch_questions";
import fetchInfo from "../../modules/fetch_info";
import QuizContent1 from "../../components/quiz_content_1/quiz_content_1";
import QuizContent2 from "../../components/quiz_content_2/quiz_content_2";
import QuizContent3 from "../../components/quiz_content_3/quiz_content_3";
import QuizContent4 from "../../components/quiz_content_4/quiz_content_4";
import QuizContent5 from "../../components/quiz_content_5/quiz_content_5";
import QuizContent6 from "../../components/quiz_content_6/quiz_content_6";
import QuizContent7 from "../../components/quiz_content_7/quiz_content_7";
import QuizCommentation from "../../components/quiz_commentation/quiz_commentation";

const CheckResultPage = () => {
  const location = useLocation();
  const hash = location.hash.substring(1);

  const [loading, setLoading] = useState(true);

  const [initInfo, setInitInfo] = useState(null);

  const initQuestions = async () => {
    try {
      const questions = await fetchQuestions();
      const questionsInfo = await fetchInfo();
      const myData = decodeURLData(hash);
      const topic = myData.recent;
      const answers = questions[topic].map((q) => q.answer);
      const myAnswers = myData["answers"][topic];
      const correctList = answers.map((answer, index) => {
        const myAnswer = myAnswers[index];
        return myAnswer !== null && myAnswer + 1 === answer;
      });
      // console.log(questions[topic]);
      const checkInfo = {
        topic: topic,
        title: questionsInfo[topic]["title"],
        sub_title: questionsInfo[topic]["title_sub"],
        name: myData["name"],
        my_answers: myData["answers"][topic],
        answers: questions[topic].map((q) => q.answer),
        questions: questions[topic],
        correct_list: correctList,
      };
      // console.log(checkInfo);
      setInitInfo(checkInfo);
    } catch (error) {
      console.error("Failed to fetch questions:", error);
    } finally {
      setLoading(false); // 로딩 상태 업데이트
    }
  };

  useLayoutEffect(() => {
    initQuestions();
  }, []);

  if (loading) {
    <div>Loading...</div>;
  }

  if (!initInfo) {
    return <div>Information not available.</div>;
  }

  return (
    <>
      <div className={styles.container}>
        <section className={styles.adBox}>광고</section>
        <section className={styles.contentWrapper}>
          <section className={styles.top}>
            <div className={styles.title}>{initInfo.title}</div>
            <div className={styles.sub_title}>{initInfo.sub_title}</div>
            <div>해설</div>
          </section>
          {Object.keys(initInfo.questions).map((index) => (
            <div key={index}>
              {initInfo.questions[index].type === 1 && (
                <>
                  <QuizContent1
                    question={initInfo.questions[index]}
                    currentQuestionIndex={Number(index)}
                    selectedAnswer={initInfo.my_answers[index]}
                    isCorrect={initInfo.correct_list[index]}
                  />
                  <QuizCommentation
                    commentation={initInfo.questions[index].comment}
                  />
                </>
              )}
              {initInfo.questions[index].type === 2 && (
                <>
                  <QuizContent2
                    question={initInfo.questions[index]}
                    currentQuestionIndex={Number(index)}
                    selectedAnswer={initInfo.my_answers[index]}
                    isCorrect={initInfo.correct_list[index]}
                  />
                  <QuizCommentation
                    commentation={initInfo.questions[index].comment}
                  />
                </>
              )}
              {initInfo.questions[index].type === 3 && (
                <>
                  <QuizContent3
                    question={initInfo.questions[index]}
                    currentQuestionIndex={Number(index)}
                    selectedAnswer={initInfo.my_answers[index]}
                    isCorrect={initInfo.correct_list[index]}
                  />
                  <QuizCommentation
                    commentation={initInfo.questions[index].comment}
                  />
                </>
              )}
              {initInfo.questions[index].type === 4 && (
                <>
                  <QuizContent4
                    question={initInfo.questions[index]}
                    currentQuestionIndex={Number(index)}
                    selectedAnswer={initInfo.my_answers[index]}
                    isCorrect={initInfo.correct_list[index]}
                  />
                  <QuizCommentation
                    commentation={initInfo.questions[index].comment}
                  />
                </>
              )}
              {initInfo.questions[index].type === 5 && (
                <>
                  <QuizContent5
                    question={initInfo.questions[index]}
                    currentQuestionIndex={Number(index)}
                    selectedAnswer={initInfo.my_answers[index]}
                    isCorrect={initInfo.correct_list[index]}
                  />
                  <QuizCommentation
                    commentation={initInfo.questions[index].comment}
                  />
                </>
              )}
              {initInfo.questions[index].type === 6 && (
                <>
                  <QuizContent6
                    question={initInfo.questions[index]}
                    currentQuestionIndex={Number(index)}
                    selectedAnswer={initInfo.my_answers[index]}
                    isCorrect={initInfo.correct_list[index]}
                  />
                  <QuizCommentation
                    commentation={initInfo.questions[index].comment}
                  />
                </>
              )}
              {initInfo.questions[index].type === 7 && (
                <>
                  <QuizContent7
                    question={initInfo.questions[index]}
                    currentQuestionIndex={Number(index)}
                    selectedAnswer={initInfo.my_answers[index]}
                    isCorrect={initInfo.correct_list[index]}
                  />
                  <QuizCommentation
                    commentation={initInfo.questions[index].comment}
                  />
                </>
              )}
            </div>
          ))}
        </section>
      </div>
    </>
  );
};

export default CheckResultPage;
