import React from "react";
import html2canvas from "html2canvas";
import styles from "./download_button.module.css";

const DownloadButton = ({ reportCardRef }) => {
  const captureAndDownload = async () => {
    if (!reportCardRef.current) return;
    try {
      const canvas = await html2canvas(reportCardRef.current);
      const link = document.createElement("a");
      link.download = "모이모이_성적통지표.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    } catch (error) {
      console.error("ReportCard를 캡쳐하는 중 오류가 발생했습니다.", error);
    }
  };

  return (
    <img
      className={styles.downloadButton}
      src={`icon_download.png`}
      onClick={captureAndDownload}
      alt={"download"}
    />
  );
};

export default DownloadButton;
