import React from "react";
import styles from "./report_card.module.css";

const ReportCard = ({ infoValue }) => {
  // console.log(infoValue);
  const student = {
    examNumber: infoValue.studentCode,
    name: infoValue.name,
    subjects: [
      {
        name: "트렌드",
        type: ["밈/유행어"],
        score: [infoValue.score.meme],
        z_score: [infoValue.z_score.meme],
        percentile: [""],
        grade: [infoValue.grade.meme],
      },
      {
        name: "덕후",
        type: ["한국영화", "해외영화", "일본애니메이션"],
        score: [
          infoValue.score.movie_ko,
          infoValue.score.movie_en,
          infoValue.score.animation_jp,
        ],
        z_score: [
          infoValue.z_score.movie_ko,
          infoValue.z_score.movie_en,
          infoValue.z_score.animation_jp,
        ],
        percentile: ["", "", ""],
        grade: [
          infoValue.grade.movie_ko,
          infoValue.grade.movie_en,
          infoValue.grade.animation_jp,
        ],
      },
    ],
  };

  const getDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}. ${month}. ${day}.`;

    return formattedDate;
  };

  const today = getDate();
  const thisYear = today.split(".")[0];

  const renderCells = (data, colspan) => {
    return data.map((content, index) => (
      <td
        key={index}
        colSpan={colspan}
        className={content === "" ? styles.diagonalLine : ""}
      >
        {content === "" || content == null ? "-" : content}
      </td>
    ));
  };

  return (
    <div className={styles.reportCard}>
      <h2>{`〈 ${thisYear}학년도 모이모이 성적통지표 〉`}</h2>
      <table className={styles.infoTable}>
        <tbody>
          <tr>
            <th>수험번호</th>
            <td colSpan="2">{student.examNumber}</td>
            <th>성 명</th>
            <td colSpan="2">{student.name}</td>
          </tr>
        </tbody>
      </table>
      <table className={styles.scoresTable}>
        <thead>
          <tr>
            <th>영역</th>
            {student.subjects.map((subject, index) => (
              <th key={index} colSpan={subject.type.length}>
                {subject.name}
              </th>
            ))}
          </tr>
          <tr>
            <th>선택과목</th>
            {student.subjects.map((subject, index) =>
              renderCells(subject.type, 1)
            )}
          </tr>
          <tr>
            <th>표준점수</th>
            {student.subjects.map((subject, index) =>
              renderCells(subject.z_score, 1)
            )}
          </tr>
          <tr>
            <th>백분위</th>
            {student.subjects.map((subject, index) =>
              renderCells(subject.percentile, 1)
            )}
          </tr>
          <tr>
            <th>등 급</th>
            {student.subjects.map((subject, index) =>
              renderCells(subject.grade, 1)
            )}
          </tr>
        </thead>
      </table>
      <div style={{ lineHeight: 0 }}>
        <h6 className={styles.today}>{today}</h6>
        <div className={styles.organization}>
          <h5 className={styles.organText}>모 이 모 이 평 가 원</h5>
          <img
            className={styles.organSign}
            src={"./moimoi_sign.png"}
            alt={"sign"}
          />
        </div>
      </div>
    </div>
  );
};
export default ReportCard;
