import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./result.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../components/modal/modal";
import ReportCard from "../../components/report_card/report_card";
import CaptureButton from "../../components/custom_button/capture_button/capture_button";
import DownloadButton from "../../components/custom_button/download_button/download_button";
import decodeURLData from "../../modules/decode_url_data";

const ResultPage = ({ shareService }) => {
  // const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);

  const [name, setName] = useState("");

  const reportCardRef = useRef(null);

  const location = useLocation();

  const [checkResult, setCheckResult] = useState(false);

  const getLocalInfo = () => {
    const jsonData = JSON.parse(localStorage.getItem("info"));
    return jsonData;
  };

  const makeShareLog = (buttonName) => {
    let localInfo = getLocalInfo();
    if (!localInfo) {
      console.log("Local Data Missing.");
      return;
    }
    let studentCode = localInfo["studentCode"];
    const timestamp = new Date().toISOString();
    const result = {
      student_code: studentCode,
      share_code: buttonName,
      clicked_time: timestamp,
    };
    return result;
  };

  /**
  URL에 해시값이 있으면 참고하고, 없으면 로컬스토리지의 값을 참고
  아무값도 참고하지 못했으면 경고 모달창
  */
  const checkAuth = useCallback(() => {
    let localInfo = "";
    if (location.hash) {
      const hash = location.hash.substring(1);
      localInfo = decodeURLData(hash);
      setCheckResult(true);
    } else {
      localInfo = getLocalInfo();
    }
    // console.log(localInfo);
    if (localInfo) {
      setInfo(localInfo);
      setName(localInfo.name);
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const navigate = useNavigate();

  //브라우저 뒤로가기를 했을 경우, 메인페이지로 넘어가는 코드
  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate("/");
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const handleCopyClipBoard = async () => {
    if (!navigator.clipboard) {
      alert("클립보드 API가 지원되지 않는 브라우저입니다.");
      return;
    }

    const obj = makeShareLog("copy");
    // console.log(obj);
    shareService.saveShareLog(obj).catch((error) => console.log(error));

    try {
      await navigator.clipboard.writeText(window.location.origin);
      alert("주소가 복사되었어요.");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // Kakao SDK 초기화
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init("cf41c10fe3fbb5694b37a5963577a880"); // 여기에 실제 JavaScript 키를 입력하세요
    }
  }, []);

  const shareToKakao = () => {
    const obj = makeShareLog("kakao");
    shareService.saveShareLog(obj).catch((error) => console.log(error));

    window.Kakao.Link.sendDefault({
      objectType: "feed",
      content: {
        title: "모이모이 모의평가!!",
        description: "내가 아는 밈, 나의 덕후력을 테스트 해보세요.",
        imageUrl: "https://your-image-url.com/image.png", // 이미지 URL을 여기에 넣습니다
        link: {
          mobileWebUrl: `${window.location.origin}`, //
          webUrl: `${window.location.origin}`,
        },
      },
      // social: {
      //   likeCount: 100,
      //   commentCount: 200,
      //   sharedCount: 300,
      // },
      buttons: [
        {
          title: "웹으로 보기",
          link: {
            mobileWebUrl: `${window.location.origin}`,
            webUrl: `${window.location.origin}`,
          },
        },
        {
          title: "앱으로 보기",
          link: {
            mobileWebUrl: `${window.location.origin}`,
            webUrl: `${window.location.origin}`,
          },
        },
      ],
    });
  };

  const shareToTwitter = () => {
    const obj = makeShareLog("twitter");
    shareService.saveShareLog(obj).catch((error) => console.log(error));

    const encodedText = encodeURIComponent(
      "내가 아는 밈, 나의 덕후력을 테스트 해보세요."
    );
    const encodedUrl = encodeURIComponent(window.location.origin);
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`;

    window.open(twitterUrl, "_blank");
  };

  const handleCheck = () => {
    const hash = location.hash.substring(1);
    navigate(`/check#${hash}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!name) {
    return (
      <>
        <div>
          <Modal modalType={"warn"} />
        </div>
      </>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <section className={styles.top}>
          <img className={styles.logo} src={"./logo3.png"} alt={"logo3"} />
        </section>
        <div ref={reportCardRef}>
          <ReportCard infoValue={info} />
        </div>
        <section className={styles.shareSection}>
          <section className={styles.shareFriend}>
            <div className={styles.shareTitle}>{`친구들에게 공유하기`}</div>
            <div className={styles.shareButtonBox}>
              <img
                className={styles.kakaoTalkButton}
                src="https://developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png"
                onClick={() => shareToKakao()}
                alt={"kakaoShare"}
              />
              <img
                className={styles.twitterButton}
                src={`icon_twitterx.png`}
                onClick={() => shareToTwitter()}
                alt={"twitterShare"}
              />
              <img
                className={styles.linkCopyButton}
                src={`icon_link2.png`}
                onClick={() => handleCopyClipBoard()}
                alt={"linkCopy"}
              />
            </div>
          </section>
          <section className={styles.showOff}>
            <div className={styles.shareTitle}>{`친구들에게 자랑하기`}</div>
            <div className={styles.showOffButtonBox}>
              <CaptureButton reportCardRef={reportCardRef} />
              <DownloadButton reportCardRef={reportCardRef} />
            </div>
          </section>
        </section>
        <section className={styles.buttonSection}>
          <section className={styles.otherSection}>
            <p className={styles.buttonTitle}>{"<<다른 문제도 풀어볼래요>>"}</p>
            <button
              className={styles.otherButton}
              onClick={() => navigate("/other")}
            >
              문제 목록
            </button>
          </section>

          {checkResult && (
            <section className={styles.checkResultSection}>
              <p className={styles.buttonTitle}>
                {"<<정답을 확인하고 싶어요>>"}
              </p>
              <button
                className={styles.checkResultButton}
                onClick={() => handleCheck()}
              >
                정답 확인
              </button>
            </section>
          )}
          <section className={styles.goMainSection}>
            <p className={styles.buttonTitle}>{"<<처음으로 돌아갈래요>>"}</p>
            <button
              className={styles.goMainButton}
              onClick={() => navigate("/")}
            >
              처음으로
            </button>
          </section>
        </section>
      </div>
    </div>
  );
};

export default ResultPage;
