import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "./coupang_ad.module.css";
const CoupangAd = () => {
  const adRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const createAd = useCallback(() => {
    if (adRef.current && window.PartnersCoupang) {
      // 기존 광고 제거
      while (adRef.current.firstChild) {
        adRef.current.removeChild(adRef.current.firstChild);
      }

      // 새 광고 생성
      new window.PartnersCoupang.G({
        id: 797098,
        template: "carousel",
        trackingCode: "AF0642065",
        width: isSmallScreen ? "300" : "540",
        height: isSmallScreen ? "250" : "350",
        tsource: "",
        container: adRef.current,
      });
    }
  }, [isSmallScreen]);

  useEffect(() => {
    const handleResize = () => {
      const newIsSmallScreen = window.innerWidth <= 540;
      if (newIsSmallScreen !== isSmallScreen) {
        setIsSmallScreen(newIsSmallScreen);
        // 화면 크기가 변경되었을 때 광고 다시 생성
        createAd();
      }
    };

    // 초기 크기 설정 및 광고 생성
    handleResize();

    // 리사이즈 이벤트 리스너 추가
    window.addEventListener("resize", handleResize);

    const script = document.createElement("script");
    script.src = "https://ads-partners.coupang.com/g.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = createAd;

    return () => {
      document.body.removeChild(script);
      window.removeEventListener("resize", handleResize);
    };
  }, [createAd, isSmallScreen]);

  return (
    <section>
      <div
        ref={adRef}
        style={{
          width: isSmallScreen ? "300px" : "540px",
          height: isSmallScreen ? "250px" : "350px",
        }}
      ></div>
      <p className={styles.ad_message}>
        이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를
        제공받습니다.
      </p>
    </section>
  );
};

export default CoupangAd;
