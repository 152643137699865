import React from "react";
import styles from "./quiz_commentation.module.css";

const QuizCommentation = ({ commentation }) => {
  const renderExampleWithLineBreaks = (text) => {
    return text?.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  return (
    <div className={styles.container}>
      <div className={styles.commentBox}>
        <p>{`<<해설>>`}</p>
        <p className={styles.comment}>
          {renderExampleWithLineBreaks(commentation)}
        </p>
      </div>
    </div>
  );
};

export default QuizCommentation;
