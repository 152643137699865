import styles from "./quiz_title.module.css";

const QuizTitle = ({ quizInfo }) => {
  return (
    <section className={styles.title}>
      <h2>{quizInfo?.title}</h2>
      <h1>{quizInfo?.title_sub}</h1>
    </section>
  );
};

export default QuizTitle;
