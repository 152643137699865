export default class ShareService {
  constructor(http) {
    this.http = http;
  }

  async saveShareLog(obj) {
    return await this.http.fetch("/share/save", {
      method: "POST",
      body: JSON.stringify({ obj }),
    });
  }
}
