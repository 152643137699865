const decodeURLData = (hash) => {
  try {
    const utf8Bytes = Uint8Array.from(atob(hash), (c) => c.charCodeAt(0));
    const jsonString = new TextDecoder().decode(utf8Bytes);
    const jsonData = JSON.parse(jsonString);
    return jsonData;
  } catch (error) {
    console.error("Error decoding data:", error);
  }
};

export default decodeURLData;
