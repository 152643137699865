import React from "react";
import styles from "./modal.module.css";
import { useNavigate } from "react-router-dom";

const Modal = ({ modalType, onClose }) => {
  const navigate = useNavigate();

  if (modalType === "finish") {
    return (
      <div className={styles.modal}>
        <div className={styles.modal_content}>
          <section className={styles.modalTitle}>
            <h2>시험시간이 종료되었습니다.</h2>
          </section>
          <section className={styles.modalMessage}>
            <p>더 이상 문제풀이를 진행하실 수 없습니다.</p>
            <p>결과를 확인하시겠습니까.</p>
          </section>
          <section className={styles.modalButton}>
            <button className={styles.okButton} onClick={onClose}>
              예
            </button>
            <button
              className={styles.noButton}
              onClick={() => {
                navigate("/other");
              }}
            >
              목록으로
            </button>
          </section>
        </div>
      </div>
    );
  }

  if (modalType === "warn") {
    return (
      <div className={styles.modal}>
        <div className={styles.modal_content}>
          <section className={styles.modalTitle}>
            <h2>잘못된 접근입니다.</h2>
          </section>
          <section className={styles.modalMessage}>
            <p>초기화면으로 이동해주세요.</p>
          </section>
          <section className={styles.modalButton}>
            <button
              className={styles.noButton}
              onClick={() => {
                navigate("/");
              }}
            >
              홈으로
            </button>
          </section>
        </div>
      </div>
    );
  }
};

export default Modal;
