import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app";
import { BrowserRouter } from "react-router-dom";
import config from "./config";
import HttpCommon from "./service/http_common";
import ScoreService from "./service/score";
import ShareService from "./service/share";

const base_url = config.http.base_url;
const httpClient = new HttpCommon(base_url);
const scoreService = new ScoreService(httpClient);
const shareService = new ShareService(httpClient);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App scoreService={scoreService} shareService={shareService} />
  </BrowserRouter>
);
