import { React, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../components/modal/modal";
import styles from "./quiz.module.css";
import Clock from "../../components/clock/clock";
import QuizTitle from "../../components/quiz_title/quiz_title";
import QuizContent from "../../components/quiz_content/quiz_content";
import QuizBottom from "../../components/quiz_bottom/quiz_bottom";
import fetchQuestions from "../../modules/fetch_questions";
import fetchInfo from "../../modules/fetch_info";

const QuizPage = () => {
  const { topic } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [timeOver, setTimeOver] = useState(false);
  const [leftTime, setLeftTime] = useState(1800); // 3 minutes in seconds
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const info = JSON.parse(sessionStorage.getItem("info"));
  // eslint-disable-next-line
  // const [containerHeight, setContainerHeight] = useState("max-content");
  const contentRef = useRef(null);

  useEffect(() => {
    const loadQuestions = async () => {
      try {
        const data = await fetchQuestions();
        if (data[topic]) {
          setQuestions(data[topic]);
          setAnswers(Array(data[topic].length).fill(null));
        } else {
          setTimeOver(true); // If the topic does not exist, end the quiz
        }
      } catch (error) {
        console.error("Failed to fetch questions:", error);
      } finally {
        setLoading(false); // 로딩 상태 업데이트
      }
    };

    loadQuestions();
    console.log(getGrade(40));
  }, [topic]);

  useEffect(() => {
    if (leftTime > 0 && questions.length > 0) {
      const timer = setTimeout(() => setLeftTime(leftTime - 1), 1000);
      return () => clearTimeout(timer);
    } else if (leftTime === 0) {
      setTimeOver(true);
    }
  }, [leftTime, questions.length]);

  // useLayoutEffect(() => {
  //   if (contentRef.current) {
  //     const contentHeight = contentRef.current.offsetHeight * 2;
  //     // console.log(contentHeight, window.innerHeight);

  //     if (contentHeight < window.innerHeight) {
  //       setContainerHeight("100vh");
  //     } else {
  //       setContainerHeight("max-content");
  //     }
  //     // console.log(containerHeight);
  //   }
  // }, [currentQuestionIndex, questions, answers]);

  const handleAnswerSelection = (answer) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = answer;
    setAnswers(newAnswers);
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
    // console.log(answers);
  };

  const calculateScore = () => {
    try {
      if (!answers || !questions) {
        return 0;
      }

      const correctAnswers = questions.map((q) => q.answer);
      const scoreList = questions.map((q) => q.score);
      // const totalScore = scoreList.reduce(
      //   (accumulator, currentValue) => accumulator + currentValue,
      //   0
      // );
      let correctScore = 0;
      let questionCount = correctAnswers.length;
      for (let i = 0; i < questionCount; i++) {
        // console.log(i + 1, Number(correctAnswers[i]), answers[i] + 1);
        if (
          answers[i] !== null &&
          Number(correctAnswers[i]) === answers[i] + 1
        ) {
          correctScore += scoreList[i];
        }
      }
      return correctScore;
    } catch (error) {
      console.error("Failed to calculate score:", error);
      return 0;
    }
  };

  const getZScore = (rawScore) => {
    const zScore = parseInt(rawScore) * 2;
    return zScore;
  };

  const getGrade = async (zScore) => {
    try {
      const data = await fetchInfo();
      if (data[topic]) {
        const g_score = data[topic].g_score;
        if (zScore >= g_score[0]) {
          return 1;
        } else if (zScore < g_score[0] && zScore >= g_score[1]) {
          return 2;
        } else if (zScore < g_score[1] && zScore >= g_score[2]) {
          return 3;
        } else if (zScore < g_score[2] && zScore >= g_score[3]) {
          return 4;
        } else if (zScore < g_score[3] && zScore >= g_score[4]) {
          return 5;
        } else if (zScore < g_score[4] && zScore >= g_score[5]) {
          return 6;
        } else if (zScore < g_score[5] && zScore >= g_score[6]) {
          return 7;
        } else if (zScore < g_score[6] && zScore >= g_score[7]) {
          return 8;
        } else if (zScore < g_score[7]) {
          return 9;
        }
      } else {
        setTimeOver(true);
      }
    } catch (error) {
      console.error("Failed to fetch questions:", error);
      return "";
    }
  };

  const getPercentile = async () => {
    //백분위는 전체 학생의 인원수와 점수가 필요하다
    return;
  };

  const saveInfo = (correctScore, zScore, grade) => {
    const savedInfo = JSON.parse(localStorage.getItem("info"));
    const sessionName = JSON.parse(sessionStorage.getItem("name"));
    const sessionStudentCode = JSON.parse(sessionStorage.getItem("code"));
    if (savedInfo && savedInfo.studentCode === sessionStudentCode) {
      // 로컬 스토리지에 정보가 있고, 세션 스토리지의 studentCode와 일치하는 경우
      const updatedInfo = {
        ...savedInfo,
        name: sessionName,
        score: {
          ...savedInfo.score,
          [topic]: correctScore, // topic 변수를 키로 사용
        },
        answers: {
          ...savedInfo.answers,
          [topic]: answers,
        },
        z_score: {
          ...savedInfo.z_score,
          [topic]: zScore,
        },
        grade: {
          ...savedInfo.grade,
          [topic]: grade,
        },
        recent: topic,
      };
      if (!updatedInfo.wait.includes(topic)) {
        updatedInfo.wait = updatedInfo.wait || []; // 기존에 wait가 없을 수 있으므로 체크
        updatedInfo.wait.push(topic); // topic을 리스트 안에 추가
      }
      localStorage.setItem("info", JSON.stringify(updatedInfo));
      // console.log("기존저장");
      // console.log("저장된 점수:", correctScore);
    } else {
      // 모든 정보를 새롭게 저장
      const value = {
        name: sessionName,
        studentCode: sessionStudentCode,
        score: {
          [topic]: correctScore, // topic 변수를 키로 사용
        },
        answers: {
          [topic]: answers,
        },
        z_score: {
          [topic]: zScore,
        },
        grade: {
          [topic]: grade,
        },
        recent: topic,
        wait: [topic],
      };
      localStorage.setItem("info", JSON.stringify(value));
    }
  };

  const handleFinish = async () => {
    const finalScore = calculateScore();
    const _zScore = getZScore(finalScore);
    const _grade = await getGrade(_zScore);
    saveInfo(finalScore, _zScore, _grade);
    navigate("/ad");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!questions.length && !timeOver) {
    return <div>No questions available for this topic.</div>;
  }

  return (
    <div>
      {timeOver ? (
        <div>
          <Modal modalType={"finish"} onClose={handleFinish} />
        </div>
      ) : (
        <div className={styles.container}>
          <Clock leftTime={leftTime} />
          <div className={styles.contentWrapper}>
            <QuizTitle quizInfo={info} />
            <QuizContent
              question={questions[currentQuestionIndex]}
              currentQuestionIndex={currentQuestionIndex}
              onAnswerSelected={handleAnswerSelection}
              selectedAnswer={answers[currentQuestionIndex]}
              contentRef={contentRef}
            />
          </div>
          <QuizBottom
            currentQuestionIndex={currentQuestionIndex}
            totalQuestions={questions.length}
            onPreviousQuestion={handlePreviousQuestion}
            onNextQuestion={handleNextQuestion}
            onFinish={handleFinish}
          />
        </div>
      )}
    </div>
  );
};
export default QuizPage;
