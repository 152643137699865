export default class ScoreService {
  constructor(http) {
    this.http = http;
  }

  async checkConnect() {
    return await this.http.fetch("/score/check", {
      method: "GET",
    });
  }

  async SaveScoreData(obj) {
    return await this.http.fetch("/score/save", {
      method: "POST",
      body: JSON.stringify({ obj }),
    });
  }
}
