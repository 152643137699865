const fetchInfo = async () => {
  const response = await fetch(`${process.env.PUBLIC_URL}/quiz_info.json`);
  if (!response.ok) {
    throw new Error("Failed to fetch information");
  }
  const data = await response.json();
  return data;
};

export default fetchInfo;
