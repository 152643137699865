import React, { useCallback, useEffect, useState } from "react";
import styles from "./quiz_list.module.css";
import { useNavigate } from "react-router-dom";
import fetchInfo from "../../modules/fetch_info";

const QuizList = () => {
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);

  const navigate = useNavigate();

  const localValue = JSON.parse(localStorage.getItem("info")) || { score: {} };
  const doneList = Object.keys(localValue.score);

  const [imageSrcList, setImageSrcList] = useState([]);

  const initializeImageSrc = useCallback(
    (data) => {
      const initialImageSrcList = {};
      Object.keys(data).forEach((type) => {
        initialImageSrcList[type] = data[type].map((item) =>
          doneList.includes(item.path)
            ? "/icon_book_open.png"
            : "/icon_book_close.png"
        );
      });
      setImageSrcList(initialImageSrcList);
    },
    [doneList]
  );

  const getInfo = useCallback(async () => {
    try {
      const _info = await fetchInfo();
      const categorizedInfo = categorizeByType(_info);
      setInfo(categorizedInfo);
      initializeImageSrc(categorizedInfo);
    } catch (error) {
      console.error("Failed to fetch information:", error);
    } finally {
      setLoading(false);
    }
  }, [initializeImageSrc]);

  const categorizeByType = (data) => {
    if (!data) {
      return;
    }
    const categorized = {};
    Object.keys(data).forEach((key) => {
      const type = data[key].type_title;
      if (!categorized[type]) {
        categorized[type] = [];
      }
      categorized[type].push(data[key]);
    });
    return categorized;
  };

  const handleMouseEnter = (type, index) => {
    const newImageSrcList = { ...imageSrcList };
    newImageSrcList[type][index] = "/icon_book_write.png";
    setImageSrcList(newImageSrcList);
  };

  const handleMouseLeave = (type, index) => {
    const newImageSrcList = { ...imageSrcList };
    newImageSrcList[type][index] = doneList.includes(info[type][index].path)
      ? "/icon_book_open.png"
      : "/icon_book_close.png";
    setImageSrcList(newImageSrcList);
  };

  useEffect(() => {
    getInfo();
    categorizeByType(info);
    // eslint-disable-next-line
  }, []);

  if (loading) {
    <div>Loading...</div>;
  }

  if (!info) {
    return <div>Information not available.</div>;
  }

  // console.log(info);

  return (
    <>
      <section className={styles.contentBox}>
        {Object.keys(info).map((type) => (
          <div key={type}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h2 className={styles.contentTitle}>{`${type} 모의고사`}</h2>
            </div>
            <ul className={styles.contentList}>
              {info[type].map((item, index) => (
                <li
                  className={styles.content}
                  key={index}
                  onClick={() => navigate(`/cover/${item.path}`)}
                >
                  <div className={styles.itemTitle}> {item.title_sub}</div>
                  <div className={styles.itemSpace}>
                    <div className={styles.itemSpaceLine}></div>
                  </div>
                  <div className={styles.itemButtonSpace}>
                    <button
                      className={styles.itemButton}
                      onMouseEnter={() => handleMouseEnter(type, index)}
                      onMouseLeave={() => handleMouseLeave(type, index)}
                      onClick={() => navigate(`/cover/${item.path}`)}
                    >
                      <img
                        src={imageSrcList[type][index]}
                        alt={`item_button-${index}`}
                      />
                    </button>
                    {/* <button
                        className={styles.itemButton}
                        onClick={() => navigate(`/cover/${item.path}`)}
                      >
                        {localValue.name && doneList.includes(item.path)
                          ? "다시풀기"
                          : "문제풀기"}
                      </button> */}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </section>
    </>
  );
};

export default QuizList;
